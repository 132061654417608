.contact {
    background: #f8f8f8;
    background-repeat: no-repeat;

    &-form {
        max-width: 500px;
        margin: 0 auto;

        p {
            text-align: center;

            .wpcf7-list-item {
                text-align: left;
            }
        }

        label {
            font-size: 1.4rem;
            cursor: pointer;
            color: #5b5b5b;
        }

        input,
        textarea,
        select,
        input[type="date"]{
            font-family: Poppins;
            width: 290px;
            box-sizing: border-box;

        }

    }

    &-details {
        @include flex(column, null, center, center, flex);
    }

    &-item {
        @include flex(column, null, center, center, flex);
        padding: 0 20px;
    }

    &-data {
        color: #fff;
        font-size: 1.8rem;
        text-align: center;
        margin: 15px;

        a {
            color: #fff;
        }

    }

}

@media screen and (max-width: 1023px) {
    .contact {
        background-image: none!important;
    }
}

@include media(tablet) {

    .contact {

        &-item {
            @include flex(row);
        }

    }

}

@include media(desktop) {

    .contact {
        background-size: auto;
        background-position: left bottom;
        background-repeat: no-repeat;

        &-form {

            input,
            textarea,
            select,
            input[type="date"]{
                width: 500px;
            }

        }

        &-details {
            @include flex(row);
        }

    }

}

