.checkmark {
    position: relative;
    top: 6px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #e9e9e9;    
    display: block;
    float: left;
    margin: 0 10px 0 0;

    &:after {
        content: '';
        display: none;
        position: absolute;
        left: 5px;
        top: 2px;
        width: 4px;
        height: 7px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

}

/*
    Checkbox
*/

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

input[type="checkbox"]:checked ~ span > .checkmark {
    background-color: $indigo;

    &:after {
        display: block;
    }

}

.wpcf7-list-item-label {
    position: relative;
}

input[type="text"],
input[type="email"],
input[type="date"],
select,
textarea {
    background: #f8f8f8;
    @include radius();
    border: 1px solid #e9e9e9;
    padding: 2rem;
    margin: 0 0 1rem;

}


input[type="submit"] {
    @include radius();
    @include transition();
    display: block;
    font-size: 1.8rem;
    font-weight: $bold;

    text-align: center;
    padding: 2rem 4rem;
    margin: 5px 0;

    border: 0;

    text-transform: uppercase;
    max-width: 320px;

    font-weight: normal;
    text-transform: none;
    margin: 0 auto;

    background: $indigo;
    color: #fff;

    &:hover {
        background: #fff;
        color: $indigo;

    }
    
    &:disabled{
        opacity: 0.2;

    }
}

textarea {
    resize: none;
}
