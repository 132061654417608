.footer {
    background-color: $indigo;

    &-logo {
        width: 160px;
        height: auto;
        margin: 0 auto;

    }

}
