.accordion {
    list-style: none;
    margin: 4rem auto;
    max-width: 760px;

    &-tab {
        position: relative;
        border-bottom: 1px solid #e7e7e7;
        padding: 20px;
        cursor: pointer;

        &:after {
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            content: '';
            border: solid #000;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 5px;
            position: absolute;
            width: 2px;
            height: 2px;
            top: calc( 50% - 10px );
            left: auto;
            right: 30px;
            background: 0 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);

        }

        &-open {

            &:after {
                -webkit-transform: rotate(-135deg);
                -ms-transform: rotate(-135deg);
                transform: rotate(-135deg);

            }

        }

    }

    &-title {
        font-size: 2rem;
        color: $indigo;
        margin: 0;
    }

    &-content {
        display: none;

        p {
            margin: 15px;
        }

    }

}
