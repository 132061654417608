.offer {

    &-box {
        width: 315px;
        @include transition();

        &:hover {
            transform: scale(1.05);
        }

    }

    &-icon {
        @include flex(null, nowrap, center, center, flex);
        background-position: bottom;
        background-repeat: no-repeat;    
        background-size: 100% auto;
        height: 120px;
        width: 315px;

    }

    &-headline {
        @include flex(null, null, center, center, flex);
        font-size: 2rem;
        font-weight: 500;
        color: $indigo;
        text-align: center;
        height: 120px;
        margin: 0 15px;
        padding: 0;

    }

    &-content {
        background: #eaeaea;
        border-radius: 0 0 10px 10px;

    }

}

.container {

    &-offer {
        @include flex(column, wrap, center, center, flex);
    }

}

@include media(mobile) {

    .container {

        &-offer {
            @include flex(row, null, flex-start, space-around);
        }
    
    }
    
}
