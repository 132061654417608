.section {

    &-title {
        font-size: 3.6rem;
        margin: 0;

        &--white {
            color: #fff;
        }

    }

    &-subtitle {
        font-size: 2.4rem;
        margin: 0;
        font-weight: normal;
        color: $indigo;
    
    }

    &-headline {
        text-align: center;

        &--white {
            color: #fff;

        }

        &--green {
            color: $green;

        }

    }

    &[id="blog"] {
        background: #f8f8f8;
    }

    &[id="dane-kontaktowe"] {
        background: $indigo;

        .section-headline {
            color: #fff;
    
        }

    }

    &-cta {
        background: #dfdfdf;
        text-align: center;

        h3 {
            font-size: 3rem;
        }

        a {
            margin: 0 auto;
        }

    }

}

@include media(desktop) {

    .section {

        &-cta {

            .container {
                @include flex(row, nowrap, center, center, flex);

            }

        }

    }

}