/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    @include flex(row, null, center, center, flex);
    position: relative;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    color: transparent;
    top: 50%;
    background: 0 0;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    padding: 0;
    border: none;
    outline: 0;
    border: solid #cccccc;
    border-width: 0 3px 3px 0;
    display: inline-block;    
    padding: 20px;
    z-index: 10;

    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    
}

.slick-prev {
    left: 30px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);

}

.slick-next {
    right: 30px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    @include flex(row, null, center, center, flex);
    display: flex;
    margin: 15px 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    li {
        float: left;
        margin: 15px;

        button {
            border: 0;
            background: #e0e0e0;
            display: block;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            outline: 0;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;
        }

        &.slick-active {
            
            button {
                color: #fff;
                background: $green;
            }

        }
        
    }
}
