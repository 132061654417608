.list {
    list-style-type: none;
    margin: 0;
    padding: 0 15px 30px;

    &-item {
        font-size: 1.6rem;
        line-height: 150%;
    }

    &-ok {
        color: $indigo;

        &-item {
            position: relative;
            padding: 6px 0 0 30px;

            &:before {
                content: '';
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAAmVBMVEU9MIP///89MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIM9MIPX6wsrAAAAMnRSTlMAAAQKDxUYHCMkKjAxODw9SEpQVFlbYmVtcYegqr/Dy8zU19rc4OLk6/H09vf6+/z9/tUn2woAAAC/SURBVCjPdZLpFoIgEIWvktm+kJWFrWSlVhjv/3AprlTcHzPwHc6swEIumzIupOCM2pYSFPdCWSn0ak4C2VZACk72UteeKB7IbwU59+SvPAt2qKNYJbdBdRyN/NxRMA0nE8xfmWfgBXgr+5xhmuQHDqHAdplmNl1gGKm7gHKPAdaZW6F/LwOW7289+HID91piUcW/uBjDOVf5eV3PyUHnWNfFmvoP3V1TL231Gzc469c0H+M8jfM37cu433//4QMMd0r6l6aMtgAAAABJRU5ErkJggg==);
                background-size: 100%;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 0;
                top: 8px;

            }

        }

    }

}
