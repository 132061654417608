.references {
    background-size: cover;
    background-attachment: fixed;

    &-item {
        @include flex(column, null, center, center, flex!important);
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
        padding: 4rem 0;
        color: #fff;

        h4 {
            font-size: 1.8rem;
            margin: 15px auto 0;
        }

        img {
            border-radius: 50%;

        }

        p {
            max-width: 600px;
        }

    }

    &-list {
        margin-top: 30px;
    }

    &-element {
        @include flex(column, null, center, center, flex);
        margin-bottom: 15px;

        h4 {
            font-size: 2rem;
            margin: 15px auto;
            padding: 0;
            text-align: center;
        }

    }

    &-thumbnail {
        
        img {
            border-radius: 50%;

        }

    }

    &-thumbnail,
    &-content {
        width: 100%;
        text-align: center;

    }

}

@include media(desktop) {

    .references {

        &-element {
            @include flex(row, nowrap);

            h4 {
                text-align: left;

            }

        }

        &-thumbnail {
            width: 200px;
            padding: 0 30px 0 0;

        }

        &-content {
            text-align: left;

        }

    }

}
